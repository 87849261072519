import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Button, Level, Columns, Container, Heading, Section, Element, Box } from 'react-bulma-components'
import FormField, { HookFormWrapper } from '../components/form/form-field'
import FormGroup from '../components/form/form-group'
import { useForm } from 'react-hook-form'
import UserFormFields from '../components/users/form/user-fields'
import { useSession } from '../stores/session'
import AddressFormFields from '../components/users/form/address-fields'
import { useProducts } from '../stores/products'
import { useOrders, useOrdersRequest } from '../stores/orders'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import Basket from '../components/basket/basket'

const OrderInfo = () => {
  const [productsState] = useProducts()
  const [sessionState, sessionActions] = useSession()
  const [ordersState, ordersActions] = useOrders()
  const { navigate, language } = useI18next()

  const { t } = useTranslation()

  //Fetch user organization
  React.useEffect(() => {
    sessionActions.getOrganization()
  }, [])
  const organization = React.useMemo(() =>
    sessionState.currentOrganization && sessionState.currentOrganization.organization
  , [sessionState.currentOrganization])

  //Form
  const { control, watch, handleSubmit } = useForm()

  //Current address
  const watchAddress = watch('address')
  const selectedAddress = React.useMemo(() => {
    if (!organization)
      return
    if (watchAddress)
      return organization.addresses.find(a => a._id === watchAddress)
    return organization && organization.addresses[0] && organization.addresses[0]
  }
  , [organization, watchAddress])

  const isDefaultAddress = React.useMemo(() => Boolean(
    organization && organization.addresses[0] &&
      selectedAddress &&
      organization.addresses[0]._id === selectedAddress._id
  )
  , [organization, selectedAddress])

  //Current contact
  const watchContact = watch('contact')
  const selectedContact = React.useMemo(() => {
    if (!organization)
      return
    if (watchAddress)
      return organization.contacts.find(a => a._id === watchContact)
    return organization && organization.contacts[0] && organization.contacts[0]
  }
  , [organization, watchContact])

  const isDefaultContact = React.useMemo(() => Boolean(
    organization && organization.contacts[0] &&
      selectedContact &&
      organization.contacts[0]._id === selectedContact._id
  )
  , [organization, selectedContact])

  //Create order and update organization if necessary
  const onSubmit = async (values) => {
    const organizationPayload = {}

    //TODO: improve this
    if (!values.contact || values.contact === '__none') {
      values.newContact.type = 'logistics'
      organizationPayload.contacts = values.defaultContact ?
        [values.newContact, ...organization.contacts] : [...organization.contacts, values.newContact]
    } else if (values.defaultContact) {
      const newDefault = organization.contacts.find(c => c._id === values.contact)
      if (newDefault)
        organizationPayload.contacts = [newDefault, ...organization.contacts.filter(c => c._id !== values.contact)]
    }
    if (!values.address || values.address === '__none') {
      values.newAddress.type = 'delivery'
      organizationPayload.addresses = values.defaultAddress ?
        [values.newAddress, ...organization.addresses] : [...organization.addresses, values.newAddress]
    } else if (values.defaultAddress) {
      const newDefault = organization.addresses.find(a => a._id === values.address)
      if (newDefault)
        organizationPayload.addresses = [newDefault, ...organization.addresses.filter(a => a._id !== values.address)]
    }

    if (values.defaultDeliveryMessage) {
      organizationPayload.defaultDeliveryMessage = values.message
    }
    if (Object.keys(organizationPayload)[0])
      await sessionActions.updateOrganization(organizationPayload)

    const deliveryAddress = values.address === '__none' || !values.address ?
      values.newAddress : organization.addresses.find(a => a._id === values.address)
    const deliveryContact = values.contact === '__none' || !values.contact ?
      values.newContact : organization.contacts.find(c => c._id === values.contact)
    await ordersActions.create({
      deliveryAddress: {
        isDeliveryAddress: true,
        isMainAddress: false,
        isBillingAddress: false,
        ...deliveryAddress
      },
      deliveryContact: {
        ...deliveryContact,
        type: 'logistics'
      },
      recoveryType: values.recoveryType,
      plannedDeliveryDate: values.plannedDeliveryDate,
      comment: values.message,
      basket: sessionState.basket,
      organization: sessionState.currentOrganization._id,
      externalRef: values.externalRef,
      language: language
    }, productsState.allPrices)
  }

  //Current pickup mode
  const watchPickup = watch('recoveryType')

  const selectedPickup = React.useMemo(() => watchPickup
    , [organization, watchPickup])


  //Post request
  const [request] = useOrdersRequest({
    key: 'post /orders'
  })

  React.useEffect(() => {
    if (request && request.status === 'success') {
      sessionActions.clearBasket()
    }
  }, [request])

  React.useEffect(() => {
    ordersActions.clearRequest({ key: 'post /orders' })
    return () => {
      ordersActions.clearRequest({ key: 'post /orders' })
    }
  }, [])


  React.useEffect(() => {
    if (sessionState.initialized && !sessionState.isAuthenticated)
      navigate('/login')
  }, [sessionState.initialized])

  return (
    <Layout logged>

      {
        request && request.status === 'success' ?
          <Section size='medium'>
            <Container>
              <Columns centered gap={8} className='has-border-right' breakpoint='desktop'>
                <Columns.Column narrow>
                  <Heading size={5} textColor='dark' display='relative'>
                    <Element renderAs='span' textColor='success' style={{ position: 'absolute', left: '-1.25em' }}>✓ </Element>
                    {t('order:confirmation.title')}
                  </Heading>
                  <p>
                    {t('order:confirmation.thx')} <br />
                    {t('order:confirmation.link.click')} <Link to='/account/orders/'>{t('order:confirmation.link.here')}</Link> {t('order:confirmation.link.show_order')}
                  </p>
                </Columns.Column>
              </Columns>
            </Container>
          </Section>
          :
          organization &&
            <Section size='medium'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Container>
                  <Level>
                    <Level.Side>
                      <Level.Item>
                        <Heading size={4}>
                          {t('order:infos:title')}
                        </Heading>
                      </Level.Item>
                    </Level.Side>
                    <Level.Side>
                      <Level.Item>
                        <Button renderAs={Link} to='/basket' color='dark' outlined>← {t('order:infos:back_to_basket')}</Button>
                      </Level.Item>
                    </Level.Side>
                  </Level>

                  <Columns className='has-border-right'>
                    <Columns.Column>
                      <Element mb={5}>

                        <Heading size={6} textColor='grey' textTransform='uppercase'>
                          {t('order:infos:pickup:title')}
                        </Heading>

                        <HookFormWrapper
                          name='recoveryType'
                          control={ control }
                          component='Radios'
                          defaultValue={'delivery'}
                          options={[
                            { value: 'delivery', label: t('order:infos:pickup:delivery') },
                            { value: 'plannedDelivery', label: t('order:infos:pickup:delivery_planned') },
                            { value: 'collectOnSite', label: t('order:infos:pickup:takeAway') },
                            { value: 'bundledWithNextOrder', label: t('order:infos:pickup:wait_other_order') }
                          ]}
                        />
                      </Element>
                      {
                        (selectedPickup === 'delivery' || selectedPickup === 'plannedDelivery') &&
                          <>
                            {
                              selectedPickup === 'plannedDelivery' &&
                                <FormGroup title={t('order:infos:delivery_planned_title')} backgroundColor='white-bis' py={3} px={3}>
                                  <FormField label={t('order:infos:delivery_date')} >
                                    <div style={{ maxWidth: 220 }}>
                                      <HookFormWrapper
                                        label={t('order:infos:delivery_date')}
                                        name='plannedDeliveryDate'
                                        control={ control }
                                        component='Date'
                                        rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
                                      />
                                    </div>
                                  </FormField>
                                </FormGroup>
                            }
                            <FormGroup title={t('order:infos:address:delivery_title')} backgroundColor='white-bis' py={3} px={3}>
                              <FormField label={t('order:infos:address:title')}>
                                <HookFormWrapper
                                  name={ 'address' }
                                  control={ control }
                                  label={t('order:infos:address:title')}
                                  component='Select'
                                  defaultValue={organization && organization.addresses[0] && organization.addresses[0]._id}
                                  options={ organization && organization.addresses.map(address => ({
                                    value: address._id,
                                    label: `${address.street} ${address.streetNumber}`
                                  })) }
                                  placeholder={t('order:infos:address:add')}
                                />
                                <FormField>
                                  <Columns>
                                    <Columns.Column narrow>
                                      {
                                        selectedAddress &&
                                          <Box my={5}>
                                            { selectedAddress.street } { selectedAddress.streetNumber }<br />
                                            { selectedAddress.zip } { selectedAddress.city } <small>{ selectedAddress.country }</small>
                                          </Box>
                                      }
                                      <HookFormWrapper
                                        name='defaultAddress'
                                        label={t('order:infos:address:save')}
                                        control={ control }
                                        component='Checkbox'
                                        disabled={ isDefaultAddress }
                                      />
                                    </Columns.Column>
                                  </Columns>
                                </FormField>
                              </FormField>
                              {
                                !selectedAddress &&
                                  <AddressFormFields
                                    formPath='newAddress'
                                    control={ control }
                                  />
                              }
                            </FormGroup>


                            <FormGroup title={t('order:infos:delivery_contact:title')} backgroundColor='white-bis' py={3} px={3}>
                              <FormField label={t('order:infos:delivery_contact:sub_title')}>
                                <HookFormWrapper
                                  name={ 'contact' }
                                  control={ control }
                                  label={t('order:infos:delivery_contact:title')}
                                  component='Select'
                                  defaultValue={organization && organization.contacts[0] && organization.contacts[0]._id}
                                  options={ organization && organization.contacts.map(contact => ({
                                    value: contact._id,
                                    label: `${contact.firstName} ${contact.lastName}`
                                  })) }
                                  placeholder={t('order:infos:delivery_contact:new')}
                                />
                                <FormField>
                                  <Columns>
                                    <Columns.Column narrow>
                                      {
                                        selectedContact &&
                                          <Box my={5}>
                                            { selectedContact.firstName } { selectedContact.lastName }<br />
                                            { selectedContact.email }<br />
                                            { selectedContact.phone }
                                          </Box>
                                      }
                                      <HookFormWrapper
                                        name='defaultContact'
                                        label={t('order:infos:delivery_contact:save_as_default')}
                                        control={ control }
                                        component='Checkbox'
                                        disabled={ isDefaultContact }
                                      />
                                    </Columns.Column>
                                  </Columns>
                                </FormField>
                              </FormField>
                              {
                                !selectedContact &&
                                  <UserFormFields
                                    control={control}
                                    formPath='newContact'
                                    noPassword
                                  />
                              }
                            </FormGroup>
                          </>
                      }

                      <FormGroup title={t('order:infos:delivery_info:title')} backgroundColor='white-bis' py={3} px={3}>
                        <FormField label={t('order:infos:delivery_info:external_ref')}>
                          <HookFormWrapper
                            name='externalRef'
                            control={ control }
                            component='Input'
                          />
                        </FormField>
                        <FormField label={t('order:infos:delivery_info:message_title')}>
                          <HookFormWrapper
                            name='message'
                            control={ control }
                            component='Textarea'
                            style={{ width: '100%', maxWidth: '100%', resize: 'vertical', minHeight: 100 }}
                            defaultValue={organization && organization.defaultDeliveryMessage}
                          />
                        </FormField>
                        <FormField label=' '>

                          <HookFormWrapper
                            name='defaultDeliveryMessage'
                            label={t('order:infos:delivery_info:save_as_default')}
                            control={ control }
                            component='Checkbox'
                          />
                        </FormField>
                      </FormGroup>
                    </Columns.Column>
                    <Columns.Column>
                      <Element mb={5}>
                        <Heading size={6} textColor='grey' textTransform='uppercase'>
                          {t('order:infos:title_2')}
                        </Heading>
                        <Basket
                          light
                          basket={ sessionState.basket }
                          addItemToBasket={ sessionActions.addItemToBasket }
                          removeItemFromBasket={ sessionActions.removeItemFromBasket }
                        //priceRequest={ priceRequest }
                        />
                      </Element>
                      {
                        /*

                      <Heading size={6} textColor='grey' textTransform='uppercase'>
                        {t('order.infos.payement')}
                      </Heading>
                      <Element backgroundColor='warning' mb={6}>?</Element>
                        */
                      }
                      <Button
                        type='submit'
                        color='primary'
                        fullwidth
                        disabled={ request && request.status === 'inProgress' }
                      >{t('order:submit')}</Button>
                    </Columns.Column>
                  </Columns>
                </Container>
              </form>
            </Section>
      }
    </Layout>
  )
}
export default OrderInfo

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`